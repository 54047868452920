


















































































































import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { CustomerGroupInfoProperties } from "./CustomerGroupInfoView.vue";
import { CustomerGroupEventDialogProperties } from './CustomerGroupEventDialogView.vue';
import CustomerGroupEventService from "@/services/CustomerGroupEventService";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import CommonUtil from '@/utilities/CommonUtil';

@Component({
    data: () => ({
        table: {
            recordCounts: [10, 20, 30, 40, 50],
            loading: false,
            pageNo: 1,
            pageCount: 1,
            recordCount: 10,
        },
        filter: {
            type: "All",
            status: "All",
        },
    }),
})
export default class CustomerGroupEventView extends Vue {
    @Prop() private properties: CustomerGroupInfoProperties;
    @Prop() private customerGroupEventDialog: CustomerGroupEventDialogProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private customerGroupEventService = new CustomerGroupEventService();

    public get customerGroup() {
        return this.properties.customerGroup;
    }

    public get customerGroupEvents() {
        return this.properties.customerGroupEvents;
    }

    public get headers() {
        return [
            {
                text: this.$t("text.type"),
                value: "type",
                width: "250px",
                filter: (v) => {
                    const type = this.$data.filter.type;
                    if (!type || type === "All") return true;
                    return v === type;
                },
            },
            {
                text: this.$t("text.status"),
                value: "statuses",
                filter: (v) => {
                    const status = this.$data.filter.status;
                    if (!status || status === "All") return true;
                    return v.some(x => x.status === status);
                },
            },
            {
                text: this.$t("text.minimum-total-amount"),
                value: "minimumTotalAmount",
                align: "right"
            },
            {
                text: this.$t("text.maximum-total-amount"),
                value: "maximumTotalAmount",
                align: "right"
            },
            {
                text: this.$t("text.actions"),
                value: "action",
                fixed: true,
                align: "center",
                width: "120px",
                sortable: false,
            },
        ];
    }

    public typeOptions(withAll: boolean = false) {
        return [
            {
                text: this.$t("text.all"),
                value: "All",
                all: true
            },
            {
                text: this.$t("text.customer-order"),
                value: "Customer-Order",
                all: false
            },
            {
                text: this.$t("text.customer-payment"),
                value: "Customer-Payment",
                all: false
            }
        ].filter(x => !x.all || withAll);
    }

    public statusOptions(type: string, withAll: boolean = false) {
        var status = [
            {
                text: this.$t("text.all"),
                value: "All",
                all: true
            }
        ];

        if (type === "Customer-Order") {
            status = status.concat([
                {
                    text: this.$t("text.to-pay"),
                    value: "To-Pay",
                    all: false
                },
                {
                    text: this.$t("text.checkout"),
                    value: "Checkout",
                    all: false
                },
                {
                    text: this.$t("text.approved"),
                    value: "Approved",
                    all: false
                },
                {
                    text: this.$t("text.paid"),
                    value: "Paid",
                    all: false
                },
                {
                    text: this.$t("text.cancelled"),
                    value: "Cancelled",
                    all: false
                },
                {
                    text: this.$t("text.rejected"),
                    value: "Rejected",
                    all: false
                },
                {
                    text: this.$t("text.expired"),
                    value: "Expired",
                    all: false
                },
                {
                    text: this.$t("text.delivered"),
                    value: "Delivered",
                    all: false
                }
            ]);
        } else if (type === "Customer-Payment") {
            status = status.concat([
                {
                    text: this.$t("text.processing"),
                    value: "Processing",
                    all: false
                },
                {
                    text: this.$t("text.approved"),
                    value: "Approved",
                    all: false
                },
                {
                    text: this.$t("text.cancelled"),
                    value: "Cancelled",
                    all: false
                },
                {
                    text: this.$t("text.rejected"),
                    value: "Rejected",
                    all: false
                },
                {
                    text: this.$t("text.refunded"),
                    value: "Refunded",
                    all: false
                },
                {
                    text: this.$t("text.delivered"),
                    value: "Delivered",
                    all: false
                },
                {
                    text: this.$t("text.completed"),
                    value: "Completed",
                    all: false
                }
            ]);
        }

        return status.filter(x => !x.all || withAll);
    }

    public formatType(item: any) {
        switch (item.type) {
            case "Customer-Order": return this.$t("text.customer-order");
            case "Customer-Payment": return this.$t("text.customer-payment");
        }
        return "-";
    }

    public formatStatus(item: any) {
        var status = []

        item.statuses.forEach(e => {
            var v = this.statusOptions(item.type)
                .find(x => x.value === e.status);
            if (v) status.push(v.text);
        }); 

        return status.length === 0 ? "-" : status.join(", ");
    }

    public filterTypeChanged() {
        this.$data.filter.status = "All";
    }

    public created() {
        this.load();
        this.properties.events.subscribe('load-events', this.load);
        this.properties.events.subscribe('alert-dialog-clicked', this.alertDialogClicked);
    }

    public destroyed() {
        this.properties.events.remove('load-events', this.load);
        this.properties.events.remove('alert-dialog-clicked', this.alertDialogClicked);
    }

    public async load(fetch: boolean = true) {
        if (this.$data.table.loading) {
            return;
        }
        this.$data.table.loading = true;

        try {
            const pageNo = this.$data.table.pageNo;
            const rowCount = this.$data.table.recordCount;
            const rowStart = (pageNo - 1) * rowCount
            const customerGroup = this.customerGroup;

            if (fetch) {
                if (customerGroup && customerGroup.id) {
                    const r = await this.customerGroupEventService.get({
                        customerGroupId: customerGroup.id,
                        loadStatuses: true
                    });                    

                    var count = Math.ceil(r.data.count / rowCount);
                    if (count < 1) count = 1;

                    const events: any[] = r.data.customerGroupEvents;
                    events.forEach(e => {
                        if (!e.statuses) e.statuses = [];
						e.minimumTotalAmount ??= 0;
						e.maximumTotalAmount ??= 0;
                    });

                    this.properties.customerGroupEvents = events;
                    this.$data.table.pageCount = count;
                    if (pageNo > count) {
                        this.$data.table.pageNo = count;
                    }
                } else {
                    this.properties.customerGroupEvents = [];
                }
            } else if (this.properties.customerGroupEvents !== null) {
                const events = this.properties.customerGroupEvents;

                var count = Math.ceil(events.length / rowCount);
                if (count < 1) count = 1;

                this.properties.customerGroupEvents = events;
                this.$data.table.pageCount = count;
                if (pageNo > count) {
                    this.$data.table.pageNo = count;
                }
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.table.loading = false;
        }
    }

    public create() {
        this.customerGroupEventDialog.isNew = true;
        this.customerGroupEventDialog.customerGroupEvent = {
            type: "Customer-Order",
            minimumTotalAmount: 0.0,
            maximumTotalAmount: 0.0,
            statuses: [],
        };
        this.customerGroupEventDialog.index = -1;
        this.customerGroupEventDialog.visible = true;
    }

    public modify(item: any) {
        var events = this.properties.customerGroupEvents;

        this.customerGroupEventDialog.isNew = false;
        this.customerGroupEventDialog.customerGroupEvent = CommonUtil.clone(item);
        this.customerGroupEventDialog.index = events.indexOf(item);
        this.customerGroupEventDialog.visible = true; 
    }

    public remove(item: any) {

    }

    public removeDialog(item: any) {
        this.alertDialog.tag = { command: "event-remove", record: item };
    }

    public alertDialogClicked(button: string) {
        const tag = this.alertDialog.tag;
        if (tag) {
            if (tag.command === "event-remove" && button === this.$t("text.yes")) {
                this.remove(tag.record);   
            }
        }
    }
}
